<template>
  <div class="content_block" style="min-height: 400px">
    <el-form ref="form" :model="form" label-width="180px">
      <el-form-item label="备件申领是否启用审批：" prop="applyApproveStatus">
        <el-radio-group v-model="form.applyApproveStatus" @change="approveStatusHandle">
          <el-radio label="1">启用</el-radio>
          <el-radio label="2">不启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备件退回是否启用审批：" prop="backApproveStatus">
        <el-radio-group v-model="form.backApproveStatus" @change="approveStatusHandle">
          <el-radio label="1">启用</el-radio>
          <el-radio label="2">不启用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { detailSystemStock, updateSystemStock } from '@/api/stock.js';
export default {
  name: 'STOCKSETTING',
  data () {
    return {
      form: {
        applyApproveStatus: undefined,
        backApproveStatus: undefined,
      },
    };
  },
  async created () {
    const rs = await detailSystemStock();
    const { applyApproveStatus, backApproveStatus } = rs.body;
    this.form.applyApproveStatus = applyApproveStatus;
    this.form.backApproveStatus = backApproveStatus;
  },
  methods: {
    async approveStatusHandle () {
      const rs = await updateSystemStock({...this.form});
      if (rs.heads.code === 200) {
        this.$message.success('更改审批成功');
      }
    },
  },
};
</script>
